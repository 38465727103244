<template>
  <div class="wx-upload-container">
    <!-- @click="img.src && onDel(img.localId)" -->
    <div
      class="list__item"
      v-for="(img, index) in localImgsData"
      :key="index"
      :sign="img.localId"
      @click="img.src && onDel(img.src)"
    >
      <img :src="preview(img)" alt="" />
      <svg-icon v-if="img.src" icon-class="icon-delete"></svg-icon>
    </div>
    <div class="upload-add-bt" @click="chooseImgs" v-if="localImgsData.length < limit">
      <svg-icon icon-class="icon-upload-plus"></svg-icon>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import wxtool from '@/utils/wx-tool';
import loading from '@/assets/images/loading.gif';

let _waited = null;
const suspend = new Promise((resolve) => {
  _waited = resolve;
});

export default {
  name: 'c__file-upload',
  props: {
    limit: {
      type: Number,
      default: 9,
    },
    prefix: {
      type: String,
      default: '',
    },
    outputImgs: {
      type: Array,
      default: null,
    },
    multiSize: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      localImgsData: [],
      localIds: [],
      imgs: [],
      postUrl: 'http://upload.qiniup.com/putb64/-1/',
      wxInstance: null,
    };
  },
  computed: {
    inWx() {
      return this.isWx();
    },
    rest() {
      return this.limit - this.localImgsData.length;
    },
  },
  watch: {
    outputImgs(val) {
      const imgs = val.map((item, index) => ({
        outid: index,
        src: item,
      }));
      this.localImgsData = imgs;
    },
    localImgsData(val) {
      const imgs = val.filter((item) => item.src).map((item) => item.src);
      this.imgs = imgs;
    },
    imgs(val) {
      let src = val.length > 0 ? val : [];
      if (this.limit === 1) {
        src = val.length > 0 ? src['0'] : '';
      }
      this.$emit('onChange', src);
    },
  },
  mounted() {
    const that = this;
    wxtool.init().then((wx) => {
      that.wxInstance = wx;
      _waited(wx);
    });
  },
  methods: {
    async chooseImgs() {
      const that = this;
      suspend
        .then(async () => {
          // choose image
          const wxLocalIds = await new Promise((resolve, reject) => {
            that.wxInstance.chooseImage({
              count: that.rest,
              success(res) {
                const { errMsg, localIds } = res;
                if (errMsg === 'chooseImage:ok') {
                  resolve(localIds);
                } else {
                  reject(new Error(errMsg));
                }
              },
            });
          });
          // wxlocalid 存变量
          that.localImgsData.push(
            ...wxLocalIds.map((item) => ({
              localId: item,
            })),
          );
          // 转base64
          const localIdWithBase64 = await Promise.all(
            that.localImgsData.map((item) => that.getBase64(item.localId)),
          );
          // 装载base64
          that.localImgsData.forEach((item, index) => {
            const search = localIdWithBase64.find((b) => b.localId === item.localId);
            if (search) {
              that.localImgsData[index].localData = search.imageBase64;
            }
          });
          // 批量上传
          await Promise.all(that.localImgsData.map((item) => that.handlerImage(item.localId)));
        })
        .catch((err) => {
          that.$notify({
            message: err.message,
            duration: 3000,
          });
        });
    },
    getBase64(localId) {
      const that = this;
      return new Promise((resolve, reject) => {
        that.wxInstance.getLocalImgData({
          localId,
          success(res) {
            const { errMsg, localData } = res;
            if (errMsg === 'getLocalImgData:ok') {
              const imageBase64 = localData.indexOf('data:image') === 0
                ? localData
                : `data:image/jpeg;base64,${localData.replace(/\n/g, '')}`;
              resolve({
                localId,
                imageBase64,
              });
            } else {
              reject(new Error(errMsg));
            }
          },
        });
      });
    },
    async operateorImgs(localId) {
      const that = this;
      await that.loadLocalImg(localId);
      // await that.handlerImage(localId);
    },
    loadLocalImg(localId) {
      const that = this;
      const { localImgsData } = that;
      return new Promise((resolve, reject) => {
        that.wx.getLocalImgData({
          localId,
          success(res) {
            const { errMsg, localData } = res;
            if (errMsg === 'getLocalImgData:ok') {
              const imageBase64 = localData.indexOf('data:image') === 0
                ? localData
                : `data:image/jpeg;base64,${localData.replace(/\n/g, '')}`;
              that.localImgsData = localImgsData.map((item) => {
                const temp = item;
                if (temp.localId === localId) {
                  temp.localData = imageBase64;
                }
                return temp;
              });
              resolve();
            } else {
              reject(errMsg);
            }
          },
        });
      });
    },
    handlerImage(localId) {
      const that = this;
      const { localImgsData } = that;
      const handleImg = localImgsData.find((item) => item.localId === localId);
      return new Promise((resolve, reject) => {
        if (handleImg) {
          const { localData } = handleImg;
          const base64Data = localData.split(',')[1];
          that.getUploadToken().then((uploadToken) => {
            axios
              .post(that.postUrl, base64Data, {
                headers: {
                  'Content-Type': 'application/octet-stream',
                  Authorization: `UpToken ${uploadToken}`,
                },
              })
              .then((res) => {
                const src = res.data.key;
                that.localImgsData = localImgsData.map((item) => {
                  const temp = item;
                  if (temp.localId === localId) {
                    temp.src = src;
                  }
                  return temp;
                });
                resolve();
              });
          });
        } else {
          reject(new Error('没有图片'));
        }
      });
    },
    getUploadToken() {
      return new Promise((resolve) => {
        const url = this.prefix ? `api/upload/token?prefix=${this.prefix}` : 'api/upload/token';
        this.$request.get(url).then((res) => {
          resolve(res.data);
        });
      });
    },
    preview(sign) {
      const img = this.localImgsData.find((item) => item.localId === sign.localId);
      // eslint-disable-next-line no-nested-ternary
      return img && img.src
        ? img.src.indexOf('http') >= 0
          ? img.src
          : `${this.imgHost}${img.src}`
        : loading;
    },
    onDel(sign) {
      const temp = this.localImgsData.filter((item) => item.src !== sign);
      this.localImgsData = temp;
    },
  },
};
</script>

<style></style>
