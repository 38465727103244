<template>
  <div class="topic-search-wrapper">
    <!-- 添加新主题 -->
    <div class="topic-add-bt" @click="onCreateTopic">
      <svg-icon class="bt-icon" icon-class="icon-plus"></svg-icon>
      <p>创建分享主题</p>
    </div>
    <!-- 搜索 -->
    <div class="part__search-container">
      <van-search @search="onSearch" v-model="keyword" placeholder="搜索"></van-search>
    </div>
    <!-- 列表 -->
    <div class="common-lists-container topic-lists-container">
      <van-list class="list__inner" v-model="requesting" :finished="finished" @load="getMore">
        <div
          class="list__item"
          v-for="(item, index) in lists"
          :key="index"
          @click="onSelected"
          :data-topicid="item.id"
          :class="{
            selected: Number(selectedTopicId) == Number(item.id)
          }"
        >
          <div class="item__icon">
            <svg-icon icon-class="icon-choose"></svg-icon>
          </div>
          <div class="item__content-info item__topic-info">
            <p># {{ item.title }}</p>
          </div>
        </div>
      </van-list>
    </div>
    <!-- 底部按钮 -->
    <div class="group__footer-fixed-nav">
      <div class="nav__item" @click="onCancel"><strong>取消</strong></div>
      <div class="nav__item black" @click="onSubmit"><strong>确认</strong></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'c__topic-search',
  props: {
    topicid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      keyword: '',
      requesting: false,
      page: 1,
      totalPage: 1,
      finished: false,
      lists: [],
      selectedTopicId: 0,
    };
  },
  mounted() {
    if (this.topicid > 0) {
      this.selectedTopicId = this.topicid;
    }
  },
  methods: {
    onSearch() {
      this.lists = [];
      this.finished = false;
      this.getMore();
    },
    onCancel() {
      this.selectedTopicId = 0;
      this.$emit('cancel');
    },
    onSubmit() {
      const that = this;
      const topicid = Number(this.selectedTopicId);
      if (topicid === 0) {
        that.$notify({
          message: '请选择一个相关话题',
        });
      } else {
        const topic = that.lists.find((item) => Number(item.id) === topicid);
        that.$emit('submit', {
          id: that.selectedTopicId,
          name: topic.title,
        });
      }
    },
    onSelected(e) {
      const { topicid } = e.currentTarget.dataset;
      this.selectedTopicId = topicid;
    },
    getMore() {
      const that = this;
      that.$request
        .get('api/user/topic/list', {
          page: that.page,
          keyword: that.keyword,
        })
        .then((res) => {
          const { data } = res;
          that.lists.push(...data.list);
          that.totalPage = data._meta.pageCount;
          if (that.page >= that.totalPage) {
            that.finished = true;
          } else {
            that.page += 1;
          }
        })
        .finally(() => {
          that.requesting = false;
        });
    },
    onCreateTopic() {
      this.$router.push({
        path: '/topic/edit',
        query: {
          from: 'edit',
        },
      });
    },
  },
};
</script>

<style></style>
