<template>
  <div class="post-edit-container topic">
    <div class="page__title">
      <svg-icon icon-class="icon-plus-square" color="#33658A"></svg-icon>
      <p><strong>发布分享</strong></p>
    </div>
    <!-- form框 -->
    <div class="edit-form-container">
      <van-form @submit="onSubmit" class="post-edit-form topic">
        <div class="form__item">
          <van-field v-model="form.content" class="textarea" type="textarea" maxlength="500" :placeholder="placeholder"
            show-word-limit :rules="[
              {
                required: true,
                message: '请填写内容',
              },
            ]">
            <template #label>
              <p><span>1、</span>编辑内容</p>
            </template>
          </van-field>
        </div>
        <div class="form__item">
          <van-field>
            <template #input>
              <wx-upload prefix="topic-post" :limit="9" :outputImgs="existsImgs" @onChange="onUploadChange"></wx-upload>
            </template>
          </van-field>
        </div>
        <div class="form__item" @click="onShowTopicChoose">
          <van-field v-model="form.topicid" type="hidden" readonly clickable :rules="[
            {
              required: true,
              message: '请选择相关主题',
            },
          ]">
            <template #label>
              <p><span class="font-red">2、</span>选择主题</p>
            </template>
            <template #extra>
              <div class="item__right-container with-bt">
                <p>{{ topicName }}</p>
                <svg-icon icon-class="icon-right"></svg-icon>
              </div>
            </template>
          </van-field>
        </div>
        <!-- 底部导航 -->
        <div class="bottom-menu-container">
          <van-button class="form-cancel-bt" native-type="button" @click="onCancel">取消</van-button>
          <van-button class="form-submit-bt" native-type="submit" :loading="requesting"
            loading-text="提交中..."><strong>确定</strong></van-button>
        </div>
      </van-form>
    </div>
    <!-- 话题选择 -->
    <div class="common-search-list-container posts-friend-select" v-if="isShowTopicSearch">
      <topic-search @cancel="onSearchCancel" @submit="onSearchSubmit" :topicid="topicid"></topic-search>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import wxUpload from '../common/_upload-bt.vue';
import topicSearch from './_topic-search.vue';

export default {
  name: 'page-topic-post-edit',
  components: {
    wxUpload,
    topicSearch,
  },
  data() {
    return {
      form: {
        content: '',
        imgs: [],
        topicid: '',
      },
      isShowTopicSearch: false,
      requesting: false,
      topicName: '',
      existsImgs: [],
    };
  },
  computed: {
    ...mapState(['forward', 'afterCreatePostLink']),
    placeholder() {
      return this.groupID > 0
        ? '分享你发现的宝藏…（组内成员可见）'
        : '分享你发现的宝藏…（主题内权限好友可见）';
    },
    topicid() {
      return Number(this.$route.params.topicid) || 0;
    },
    groupID() {
      return this.$route.query.groupID || 0;
    },
    topicname() {
      return this.$route.query.topicname;
    },
  },
  watch: {
    $route: {
      handler(val) {
        if (val.query.topicname) {
          this.topicName = val.query.topicname;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.topicname === undefined) {
      this.onCloseTopicChoose();
    }
    const temp = this.$store.state.tempCreateTopicPost;
    if (temp !== null) {
      this.form = {
        content: temp.content,
        imgs: temp.imgs,
        topicid: this.topicid,
      };
    } else {
      this.form = {
        content: '',
        imgs: '',
        topicid: this.topicid,
      };
    }
    this.existsImgs = this.form.imgs === '' ? [] : this.form.imgs;
  },
  destroyed() {
    console.log('destroyed');
  },
  methods: {
    onUploadChange(val) {
      this.form.imgs = val.length > 0 ? val : '';
    },
    onSubmit() {
      const that = this;
      if (!that.requesting) {
        that.requesting = true;
        let postURI = 'api/user/topic/post/create';
        if (that.groupID > 0) {
          postURI = `${postURI}?groupID=${that.groupID}`;
        }
        that.$request
          .post(postURI, that.form)
          .then((res) => {
            // that.$router.go(-1);
            that.$notify({
              type: 'success',
              message: '操作成功',
            });

            let replacePath;
            if (that.afterCreatePostLink) {
              replacePath = that.afterCreatePostLink;
            } else {
              const url = that.groupID > 0
                ? `/group/${that.groupID}/topic/${res.data.post.topic_id}`
                : `/topic/${res.data.post.topic_id}`;
              replacePath = this.forward ? this.forward : url;
            }
            that.$router.replace({
              path: replacePath,
            });
            that.$store.commit('REMOVE_TEMP_TOPIC_POST');
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    },
    onShowTopicChoose() {
      const that = this;
      console.log(that.form);
      that.$store.commit('SET_TEMP_TOPIC_POST', that.form);
      that.isShowTopicSearch = true;
    },
    onCloseTopicChoose() {
      this.form.topicid = '';
      this.topicName = '';
      this.isShowTopicSearch = false;
    },
    onSearchCancel() {
      this.onCloseTopicChoose();
    },
    onSearchSubmit(topic) {
      this.onCloseTopicChoose();
      this.form.topicid = topic.id;
      this.topicName = topic.name;
    },
    onCancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style></style>
