var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"post-edit-container topic"},[_c('div',{staticClass:"page__title"},[_c('svg-icon',{attrs:{"icon-class":"icon-plus-square","color":"#33658A"}}),_vm._m(0)],1),_c('div',{staticClass:"edit-form-container"},[_c('van-form',{staticClass:"post-edit-form topic",on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"form__item"},[_c('van-field',{staticClass:"textarea",attrs:{"type":"textarea","maxlength":"500","placeholder":_vm.placeholder,"show-word-limit":"","rules":[
            {
              required: true,
              message: '请填写内容',
            },
          ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',[_c('span',[_vm._v("1、")]),_vm._v("编辑内容")])]},proxy:true}]),model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('div',{staticClass:"form__item"},[_c('van-field',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('wx-upload',{attrs:{"prefix":"topic-post","limit":9,"outputImgs":_vm.existsImgs},on:{"onChange":_vm.onUploadChange}})]},proxy:true}])})],1),_c('div',{staticClass:"form__item",on:{"click":_vm.onShowTopicChoose}},[_c('van-field',{attrs:{"type":"hidden","readonly":"","clickable":"","rules":[
          {
            required: true,
            message: '请选择相关主题',
          },
        ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',[_c('span',{staticClass:"font-red"},[_vm._v("2、")]),_vm._v("选择主题")])]},proxy:true},{key:"extra",fn:function(){return [_c('div',{staticClass:"item__right-container with-bt"},[_c('p',[_vm._v(_vm._s(_vm.topicName))]),_c('svg-icon',{attrs:{"icon-class":"icon-right"}})],1)]},proxy:true}]),model:{value:(_vm.form.topicid),callback:function ($$v) {_vm.$set(_vm.form, "topicid", $$v)},expression:"form.topicid"}})],1),_c('div',{staticClass:"bottom-menu-container"},[_c('van-button',{staticClass:"form-cancel-bt",attrs:{"native-type":"button"},on:{"click":_vm.onCancel}},[_vm._v("取消")]),_c('van-button',{staticClass:"form-submit-bt",attrs:{"native-type":"submit","loading":_vm.requesting,"loading-text":"提交中..."}},[_c('strong',[_vm._v("确定")])])],1)])],1),(_vm.isShowTopicSearch)?_c('div',{staticClass:"common-search-list-container posts-friend-select"},[_c('topic-search',{attrs:{"topicid":_vm.topicid},on:{"cancel":_vm.onSearchCancel,"submit":_vm.onSearchSubmit}})],1):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("发布分享")])])
}]

export { render, staticRenderFns }